.container {
  padding: 0 20px;

  @include breakpoint($xs) {
    @include container(auto);
  }

  @include breakpoint($sm) {
    @include container(540px);
  }

  @include breakpoint($md) {
    @include container(720px);
  }

  @include breakpoint($lg) {
    @include container(960px);
  }

  @include breakpoint($xl) {
    @include container(1170px);
  }
}
