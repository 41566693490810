.post-card {
  display: block;
  width: 100%;
  min-height: 250px;
  border-radius: 4px;
  overflow: hidden;
  background-color: $bg-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  margin-bottom: 5.26316%;
  border-bottom: 0;
  transition: box-shadow .25s ease;

  &:hover,
  &:focus {
    border-bottom: 0;
    box-shadow: 0 2px 40px 0 hsla(232, 8%, 63%, .3);
  }

  @include breakpoint($md) {
    width: 48.4375%;
    margin-right: 3.125%;

    &:last-of-type,
    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  @include breakpoint($xl) {
    width: 31.25%;
    margin-right: 3.125%;

    &:nth-child(2n+2) {
      margin-right: 3.125%;
    }

    &:last-of-type,
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
}

.post-card__thumb {
  margin: 0;
  background: $bg-color;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    // 16:9 = 56.25% = calc(9 / 16 * 100%)
    padding-bottom: 56.25%;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.post-card__inner {
  padding: 1.875rem 1.25rem 0.625rem;
  color: $grey;
}

.post-card__header {
  margin-bottom: 0.75rem;

  .post-card__meta {
    font-size: 0.875rem;
  }
}
