.to-top {
  position: fixed;
  display: block;
  text-align: center;
  right: 15px;
  bottom: 0;
  cursor: pointer;
  height: 35px;
  width: 35px;
  background-color: rgba(103, 103, 103, 0.65);
  z-index: 2;
  transform: translate3d(0, 60px, 0);
  transition: all 0.3s ease;

  @include breakpoint($md) {
    right: 1.8em;
  }

  &.js-show {
    transform: translate3d(0, -15px, 0);

    @include breakpoint($md) {
      transform: translate3d(0, -28px, 0);
    }
  }

  &:hover {
    background-color: rgba(103, 103, 103, 0.80);
  }
}
